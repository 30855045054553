<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>Add a new user</h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <form v-on:submit="saveUser" class="user-form">
            <div class="grid-x grid-padding-x">
              <div class="cell small-12 medium-6">
                <label>Email address<sup class="text-red">*</sup></label>
                <input required type="text" v-model="user.email_address">
                <label>Password<sup class="text-red">*</sup></label>
                <input required type="password" v-model="user.password">
                <label>Re-enter Password<sup class="text-red">*</sup></label>
                <input required type="password" v-model="user.passwordCheck">
                <label>Forename<sup class="text-red">*</sup></label>
                <input required type="text" v-model="user.first_name">
                <label>Surname<sup class="text-red">*</sup></label>
                <input required type="text" v-model="user.last_name">
                <button class="button">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
      <span class="close-message" v-on:click="clearMessage">X</span>
      <p class="message-text">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'AddUser',
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        email_address: '',
        password: '',
        userlevel: 0,
      },
      message: '',
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    saveUser(event) {
      event.preventDefault();
      if (this.user.password === this.user.passwordCheck) {
        axios.post(`/users/createAccount.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, this.user)
          .then((response) => {
            if (response.data.response === true) {
              this.message = 'The new user has been added successfully';
              this.resetUser();
            } else {
              this.message = 'The new user could not be added - please check the details';
            }
          });
      } else {
        this.message = 'The passwords entered do not match. Please check.';
      }
    },
    resetUser() {
      this.user = {
        first_name: '',
        last_name: '',
        email_address: '',
        password: '',
        type: 0,
        notes: '',
        fileOneName: '',
        fileTwoName: '',
        fileThreeName: '',
      };
    },
    clearMessage() {
      this.message = '';
    },
  },
};
</script>
